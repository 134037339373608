<template>
  <div class="wrap-edit jsydghxkz">
    <el-card>
        <FloatTitBtn>
        <div class="edit-header">
          <span>临时建设用地规划许可证</span>
        <div>
          <el-button type="primary" @click="save" v-if="!readOnly"
            >保存</el-button
          ><el-button type="primary" @click="print">打印</el-button>
        </div>
        </div>
        </FloatTitBtn>
      <div class="edit-container">
        <el-form
          :disabled="readOnly"
          ref="baseform"
          label-position="left"
          :model="form"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="项目代码：">
                <el-input v-model="form.XMDM" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="文号：">
                <el-input v-model="form.WH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="用地单位：">
                <el-input v-model="form.YDDW"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="用地项目名称：">
                <el-input v-model="form.YDXMMC"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="用地位置：">
                <el-input v-model="form.YDWZ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="用地性质：">
                <el-input v-model="form.YDXZ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用地面积：">
                <el-input v-model="form.YDMJ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建设规模：">
                <el-input
                  v-model="form.JSGM"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="附件附图名称：">
                <el-input
                  v-model="form.FTMC"
                  type="textarea"
                  :rows="10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发证日期：">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.FZRQ"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
        <div v-show="false">
      <div id="form2">
       <div style="font-size: 17px;color: black;height:100%;position:relative;">
         <div style="width:49%;display:inline-block;height:100%;">
           <div style="margin-top: 210px;margin-left:75%;font-size:16px;white-space: nowrap;">{{ form.WH }}&ensp;</div>
           <div style="margin-top: 410px;margin-left:74%;">慈溪市自然资源和规划局</div>
           <div style="margin-top: 40px;margin-left:75%;">{{ getDate(form.FZRQ) }}&ensp;</div>
         </div>
         <div style="display:inline-block;width:300px;position:absolute;top:8%;left:76.5%;">
           <div style="height: 40px;">
             {{ form.YDDW }}&ensp;
           </div>
           <div style="height: 40px;">
             {{ form.YDXMMC }}&ensp;
           </div>
           <div style="height: 40px;">
            {{form.PZYDJG}}&ensp;
           </div>
           <div style="height: 40px;">
            {{form.PZYDWH}}&ensp;
           </div>
           <div style="height: 40px;">
             {{ form.YDWZ }}&ensp;
           </div>
           <div style="height: 40px;">
             {{ form.YDMJ }}&ensp;
           </div>
           <div style="height: 40px;">
             {{form.YDXZ}}&ensp;
           </div>
           <div style="height: 40px;">
             {{form.JSGM}}&ensp;
           </div>
           <div style="height: 40px;">
             {{form.TDQDFS}}&ensp;
           </div>
         </div>
         <div style="position:absolute;top:63%;left:67%;">
             <div v-for="(v, i) in picNameArray" :key="i" style="font-size: 17px;width:400px;">
                <span style="line-height:10px;">{{ v }}</span>
              </div>
           </div>
          <div style="position: absolute;top: 0;margin-top: 140px;margin-left: 210px;"><span style="font-size: 45px;color: black;">临时</span></div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import print from "print-js";
import dayjs from 'dayjs';
import mgr from "@/services/security.js";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
export default {
  name:'lsjsydghxkz',
  components: { FloatTitBtn },
  data() {
    return {
      readOnly: true,
      form: {
        Id: "",
        BJID: "",
        WH: "",
        XMDM: "",
        YDDW: "",
        YDXMMC: "",
        YDWZ: "",
        YDXZ: "",
        YDMJ: "",
        JSGM: undefined,
        FTMC: "",
        FZRQ: new Date(),
        JBR: "",
        JBRXM: "",
        JBRQ: new Date(),
      },
      apiUrl: this.$appConfig.apiUrls.ywApi,
      picNameArray:['',''],
    };
  },
  methods: {
    getDate(date) {
      var a = date
      a= dayjs(date).format('YYYY年MM月DD日')
      return a
    },
    print() {
      print({
        printable: "form2",
        type: "html",
        scanStyles: false,
        css: "/static/landscapeA3.css",
        documentTitle: "",
      });
    },
    async fetch() {
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
              "/api/YW/Getjsydghxkz?BJID=" +
              this.form.BJID
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              this.form = response.data.Data;
              this.form.XMDM = this.$store.state.XMDM;
              if(this.form.FTMC==null||this.form.FTMC==undefined){
                this.form.FTMC=''
              }
              // this.form.FZRQ=new Date()
              this.picNameArray=this.form.FTMC.split('\n')
            } else {
              throw response.data.ErrorMessage;
            }
          });

        const user = await mgr.getUser();
        if (this.form.JBR === user.profile.sub && this.$route.query.taskId) {
          this.readOnly = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      try {
        await this.$ajax
          .post(
            this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatejsydghxkz",
            this.form
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              console.log(response);
              this.form = response.data.Data;
              if(this.form.FTMC==null||this.form.FTMC==undefined){
                this.form.FTMC=''
              }
              this.picNameArray=this.form.FTMC.split('\n')
              ElMessage({
                type: "success",
                message: "保存成功!",
              });
            } else {
              ElMessage({
                type: "info",
                message: "保存失败!",
              });
              throw response.data.ErrorMessage;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? "";
    this.fetch();
  },
};
</script>


<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.el-input-number {
  flex: 1;
}
</style>

<style lang="scss">
.jsydghxkz {
  .el-form-item__content {
    display: flex;
    align-items: center;
  }

  input[min] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: start;
  }
}
</style>
